function Process() {
  const steps = [
    {
      number: 1,
      icon: "call",
      title: "Entretien initial par appel",
      description: "",
    },
    {
      number: 2,
      icon: "sticky_note_2",
      title: "Évaluation des besoins",
      description: "",
    },
    {
      number: 3,
      icon: "map",
      title: " Proposition d'un plan de travail",
      description: "",
    },
    {
      number: 4,
      icon: "handshake",
      title: "Réunion finale et inscription",
      description: "",
    },
    {
      number: 5,
      icon: "auto_stories",
      title: "Début des cours et suivi continu",
      description: "",
    },
  ];
  return (
    <section id="process" className="mt-10 sm:mt-20  flex flex-col  items-center pb-20 w-full">
      <p className=" pt-10 sm:pt-20 text-[35px] sm:text-[50px] font-figtree text-center ">
        Notre<span className="text-[#0083FF] "> démarche</span>
      </p>
      <p className=" text-[15px] sm:text-[20px] pb-10 sm:pb-20 px-[5%] font-nexa-normal text-center text-gray-500  ">
        Ce processus est{" "}
        <span className="font-nexa-bold !text-gray-900">sans engagement</span>{" "}
        et <span className="font-nexa-bold !text-gray-900">gratuit</span> vous
        ne payez que lorsque l'offre vous convient.{" "}
      </p>
      <div className="relative flex flex-col sm:flex-row bg-blue-50 px-5 py-16 sm:p-20 sm:justify-center  sm:items-center w-full cursor-default ">
        <div className="absolute z-20 h-[90%] sm:w-[90%]  w-1 sm:h-1 left-1/2 sm:top-1/2 -translate-x-1/2 sm:-translate-y-1/2 bg-[#0083FF]"></div>

        {steps.map((item, index) => (
          <div className="relative flex  flex-1 odd:justify-start even:justify-end w-full">
            <div
              key={index}
              className="flex flex-col  max-w-[50%] sm:max-w-full sm:gap-16 text-white px-5 py-3 sm:p-5"
            >
              <span className="material-icons-outlined text-center text-[40px] sm:text-[50px] text-[#0083FF]">
                {item.icon}
              </span>
              <p className="font-nexa-bold pt-3 sm:pt-5 text-[18px] sm:text-[20px] text-center text-gray-700">
                {item.title}
              </p>
              <div className="absolute flex items-center justify-center z-30 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#0083FF] border-[4px] size-10 border-[#0083FF] rounded-full ">
                <span className="flex items-center justify-center  font-nexa-bold text-center text-[18px] leading-[18px] translate -y-1/2 text-white ">
                  {item.number}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Process;
