import img_cad from "../assets/img-cours-a-domicile.jpg";
import img_cs from "../assets/img-cours-specialises.jpg";
import img_coach_sc from "../assets/img-coach-sco.jpeg";
import { Link } from "react-router-dom";
function Sevices() {
  return (
    <section id="services" className="flex flex-col gap-[100px]  px-[5%]  ">
      <div>
        {" "}
        <p className="pt-20 text-[40px] sm:text-[50px] font-figtree text-center ">
          Réveillez le <span className="text-[#0083FF] ">génie</span>
        </p>
        <p className=" text-[20px] sm:text-[25px] font-nexa-normal text-center text-gray-700  ">
          Plusieurs services à votre disposition pour surpasser vos attentes{" "}
        </p>
      </div>

      <div className="flex flex-col md:flex-row px-2 pt-2 pb-4 sm:p-10 bg-blue-50 rounded-[10px]  gap-5 sm:gap-12 border border-blue-100 shadow-md sm:shadow-sm">
        <div className="sm:flex-1">
          {" "}
          <img
            src={img_cad}
            alt="enseignant avec des élèves"
            className="w-full sm:w-full h-[300px] sm:h-full  object-cover rounded-[10px] "
          />
        </div>
        <div className="flex-1">
          <div className="flex justify-center sm:justify-start">
            <span className="bg-[#0EAD8E] px-5 py-1 sm:py-2 rounded-sm font-nexa-bold  text-white ">
              Populaire
            </span>
          </div>
          <h1 className=" py-2  font-figtree  text-center sm:text-start text-[30px] sm:text-[50px] text-[#0083FF] ">
            Cours à domicile
          </h1>
          <p className="p-3 text-[16px] sm:text-[20px] text-gray-700 font-nexa-normal text-justify ">
            Ce programme est approprié pour des cours de soutien sur une longue
            durée et sur plusieurs matières, permettant ainsi de sereinement
            préparer les évaluations séquentielles, les examens de passage et
            les examens officiels (CEP, FSLC, BEPC, PROBATOIRE, BAC, GCE O/A
            Level).
          </p>

          <Link
            to="/cours-a-domicile"
            className="w-full flex justify-center  py-3 font-nexa-bold bg-[#0013FF] text-white text-[20px]  rounded-md "
          >
            En savoir plus
          </Link>
        </div>
      </div>

      {/* 2 */}
      <div className="flex flex-col md:flex-row px-2 pt-2 pb-4 sm:p-10 bg-blue-50 rounded-[10px]  gap-5 sm:gap-12 border border-blue-100 shadow-md sm:shadow-sm">
        <div className="flex-1">
          {" "}
          <img
            src={img_cs}
            alt="enseignant avec des élèves"
            className="w-full sm:w-full h-[300px] sm:h-full  object-cover rounded-[10px] "
          />
        </div>
        <div className="sm:flex-1">
          <div className="flex justify-center sm:justify-start">
            <span className="bg-[#5A199B] px-5 py-1 sm:py-2 rounded-sm font-nexa-bold  text-white ">
              Nouveau
            </span>
          </div>
          <h1 className=" py-2 text-center sm:text-start font-figtree text-[30px] sm:text-[50px] text-[#0083FF] ">
            Cours spécialisés
          </h1>
          <p className="p-3 text-[16px] sm:text-[20px] text-gray-700 font-nexa-normal text-justify ">
            Ici, l'objectif est de travailler sur un problème particulier (un
            cours non compris, une dificulté particulière, préparer un examen
            juste pour quelques jours, traiter quelques exercices). C'est une
            formule courte durée qui peut s'étaler juste sur quelques séances
            dans tout le confort de votre domicile.
          </p>
          <Link
            to={"/cours-specialises"}
            className=" flex justify-center w-full py-3 font-nexa-bold bg-[#0013FF] text-white text-[20px] rounded-md "
          >
            En savoir plus
          </Link>
        </div>
      </div>

      {/* 3 */}

      <div className="flex flex-col md:flex-row px-2 pt-2 pb-4 sm:p-10 bg-blue-50 rounded-[10px]  gap-5 sm:gap-12 border border-blue-100 shadow-md sm:shadow-sm">
        <div className="sm:flex-1">
          <img
            src={img_coach_sc}
            alt="ensseignant avec des élèves"
            className="w-full sm:w-full h-[300px] sm:h-full  object-cover rounded-[10px] "
          />
        </div>
        <div className="flex-1">
          <h1 className=" py-2 text-center font-figtree text-[30px] sm:text-[50px] text-[#0083FF] ">
            Coaching scolaire
          </h1>
          <p className="p-3 text-[16px] sm:text-[20px] text-gray-700 font-nexa-normal text-justify ">
            Plusieurs élèves et même bacheliers se trouvent dans des situations
            difficiles. Vous avez des enfants récalcitrants et qui ne veulent
            plus étudier? Vous ne savez pas comment apprendre? quelle serie
            choisir, quelle orientation prendre après votre baccalauréat? Vous
            voulez discuter avec un professionnel de votre métier de rêve? Ceci
            est fait pour vous. Faites-vous coaché par un expert expérimenté et
            passioné.
          </p>
          <Link
            to={"/coaching-scolaire"}
            className="w-full flex justify-center py-3 font-nexa-bold bg-[#0013FF] text-white text-[20px] rounded-md "
          >
            En savoir plus
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Sevices;
