import classNames from "classnames";
import { useState } from "react";
function Subjects() {
  const [openScience, setOpenScience] = useState(true);
  const [openLitt, setOpenLitt] = useState(false);
  const [openEtran, setOpenEtran] = useState(false);
  return (
    <section id="subjects" className="mt-20 px-[5%] flex flex-col  items-center pb-20 w-full">
      <p className="py-20 text-[35px] sm:text-[50px] font-figtree text-center ">
        Les <span className="text-[#0083FF] ">Matières</span> enseignées
      </p>
      <div className="flex flex-col gap-5 justify-center w-full sm:w-[800px] ">
        <div className="bg-[#5A199B] py-3 px-4 rounded-[10px]">
          <h3
            onClick={() => setOpenScience((prev) => !prev)}
            className={classNames(
              "flex justify-between items-center  text-[20px] text-center sm:text-[25px] font-nexa-bold text-white cursor-pointer",
            )}
          >
            Matières scientifiques{" "}
            <span className="material-icons text-[30px] ">
              {openScience ? "remove" : "add"}
            </span>
          </h3>
          <ul
            className={classNames(
              openScience ? "max-h-[250px] " : "max-h-0",
              " flex flex-col gap-2 pt-5 text-[16px] transition-[max-height] duration-500 ease-in overflow-hidden  sm:text-[20px] font-nexa-normal text-white",
            )}
          >
            <li>Mathématiques</li>
            <li>Physique</li>
            <li>Chimie</li>
            <li>SVTEEHB</li>
            <li>Informatique</li>
            <li>Initiation à la programmation</li>
          </ul>
        </div>
        {/* littéraires */}

        <div className="bg-[#0099FF] py-3 px-4 rounded-[10px]">
          <h3
            onClick={() => setOpenLitt((prev) => !prev)}
            className="flex justify-between items-center  text-[20px] text-center sm:text-[25px] font-nexa-bold text-white cursor-pointer"
          >
            Matières littéraires{" "}
            <span className="material-icons text-[30px] ">
              {openLitt ? "remove" : "add"}
            </span>
          </h3>
          <ul
            className={classNames(
              openLitt ? "max-h-[400px] " : "max-h-0",
              " flex flex-col gap-2 pt-5 text-[16px] transition-[max-height] duration-500 ease-in overflow-hidden  sm:text-[20px] font-nexa-normal text-white",
            )}
          >
            <li>Français</li>
            <li>Philosophie</li>
            <li>Anglais</li>
            <li>Littérature</li>
            <li>Intensive English</li>
            <li>Histoire</li>
            <li>Géographie</li>
            <li>Éducation Civique et Morale</li>
          </ul>
        </div>

        {/* étrangères */}

        <div className="bg-[#0EAD8E] py-3 px-4 rounded-[10px]">
          <h3
            onClick={() => setOpenEtran((prev) => !prev)}
            className="flex justify-between items-center  text-[20px] text-center sm:text-[25px] font-nexa-bold text-white cursor-pointer"
          >
            Langues étrangères{" "}
            <span className="material-icons text-[30px] ">
              {openEtran ? "remove" : "add"}
            </span>
          </h3>
          <ul
            className={classNames(
              openEtran ? "max-h-[200px] " : "max-h-0",
              " flex flex-col gap-2 box-border pt-5 text-[16px] transition-[max-height] duration-500 ease-in overflow-hidden sm:text-[20px] font-nexa-normal text-white",
            )}
          >
            <li>Allemand</li>
            <li>Espagnol</li>
            <li>Chinois</li>
            <li>Latin</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Subjects;
