import teacher_student from "../assets/hero-image.png";
import student from "../assets/fair-student.jpg";
import { Link } from "react-router-dom";

function Hero() {
  const scrollToAnchor = (anchorId) => {
    const anchor = document.getElementById(anchorId);
    if (anchor) {
      const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <section className=" flex flex-col sm:flex-row sm:flex-1 sm:gap-20  mt-28  text-[#101010]  bg-white px-[5%] ">
      <div className="sm:w-[60%] h-screen sm:h-auto flex flex-col  gap-7 pt-5 sm:pt-[70px] ">
        <p className=" font-figtree text-center sm:text-left text-[40px] leading-[40px] sm:text-[50px] sm:leading-[60px]   ">
          <span className="text-part-1">Libérez le potentiel de</span>{" "}
          <span className="text-part-2"> votre enfant</span>{" "}
          <span className="text-part-3">avec nos</span>{" "}
          <span className="text-[#0099FF] text-part-4"> cours à domicile</span>{" "}
          <span className="text-part-5">sur mesure</span>.
        </p>
        <p className="font-nexa-normal text-justify text-[17px] sm:text-[20px]  text-gray-600 ">
          Chaque enfant est un génie et le révéler nécessite un cadre où il est
          épanoui (à la maison) et un encadrement de choix (Flexing Academy).
        </p>{" "}
        <div className="flex flex-col sm:flex-row gap-3 sm:gap-10 pt-2 sm:pt-0 text-[18px] font-nexa-bold">
          <button
            onClick={() => {
              scrollToAnchor("contact");
            }}
            className="border-2 border-[#0013FF] px-10 py-3 rounded-full bg-[#0013FF] text-white shadow-md  hover:shadow-none "
          >
            Nous Contacter
          </button>
          <Link
            to={"https://calendly.com/flexingacademy/appel"}
            className="flex justify-center border border-[#0013FF] px-10 py-3  rounded-full text-[#0013FF] hover:bg-blue-50 shadow-md  hover:shadow-none "
          >
            ÊTRE RAPPELÉ{" "}
          </Link>
        </div>
      </div>
      <div className="relative flex  sm:flex-1 h-[500px] sm:h-full">
        <img
          src={teacher_student}
          alt="student"
          className="hero-img h-full w-full sm:w-[400px] object-cover rounded-t-full rounded-[20px] "
        />
      </div>
    </section>
  );
}

export default Hero;
