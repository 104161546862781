import ele_levent_main from "../assets/eleves-levent-main.jpg";
function Classes() {
  return (
    <section
      id="classes"
      className="mt-10 sm:mt-20 px-[5%] flex flex-col  items-center pb-20 w-full"
    >
      <p className="py-10 sm:py-20 text-[35px] sm:text-[50px] font-figtree text-center ">
        Les <span className="text-[#0083FF] ">classes</span> enseignées
      </p>
      <div className="flex flex-col sm:flex-row justify-center gap-10 w-full">
        <div className="bg-green-200 py-5 px-4 rounded-[10px] text-center w-full sm:w-[500px] ">
          <h3 className=" items-center pb-5  text-[25px]  sm:text-[25px] font-nexa-bold  cursor-pointer">
            Enseignements <span className="text-[#0013FF] ">francophones</span>
          </h3>
          <ul className="font-nexa-normal text-[17px] ">
            <h3 className="pb-1 text-[18px] sm:text-[20px] font-nexa-bold ">
              Primaire
            </h3>
            CE1, CE2, CM1 & CM2
            <h3 className="pb-1 pt-4 text-[18px] sm:text-[20px] font-nexa-bold ">
              1er Cycle secondaire
            </h3>
            6ème, 5ème, 4ème & 3ème
            <p>Ordinaires et Bilingues</p>
            <h3 className="pb-1 pt-4 text-[18px] sm:text-[20px] font-nexa-bold ">
              2nd Cycle secondaire
            </h3>
            <li>Seconde A & C</li>
            <li>Première A, C, D & Ti</li>
            <li>Terminale A, C, D & Ti</li>
          </ul>
        </div>
        <div className="bg-purple-200 py-5 px-4 rounded-[10px] text-center w-full sm:w-[500px] ">
          <h3 className=" items-center pb-5  text-[25px]  sm:text-[25px] font-nexa-bold  cursor-pointer">
            Enseignements <span className="text-[#0013FF] ">anglophones</span>
          </h3>
          <ul className="font-nexa-normal text-[17px]">
            <h3 className="pb-1 text-[18px] sm:text-[20px] font-nexa-bold ">
              Primaire
            </h3>
            Class 3, 4, 5 and 6
            <h3 className="pb-1 pt-4 text-[18px] sm:text-[20px] font-nexa-bold ">
              1er Cycle secondaire
            </h3>
            Form 1, 2, 3, 4 and 5<p>Arts, Science and Commercial</p>
            <h3 className="pb-1 pt-4 text-[18px] sm:text-[20px] font-nexa-bold ">
              2nd Cycle secondaire
            </h3>
            <li>Lowersixth</li>
            <li>Uppersixth</li>
            <li>Arts, science and Commercial</li>
          </ul>
        </div>
      </div>
      <img
        src={ele_levent_main}
        alt="élèves qui lèvent la main"
        className="rounded-md w-full mt-10 sm:w-[80%] sm:h-[400px] object-cover  "
      />
    </section>
  );
}

export default Classes;
