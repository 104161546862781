import CustomerTestimonial from "./CustomerTestimonial";
import default_profile from "../assets/default_profile.png";
import Carousel from "./Carousel";
function Testimonies() {
  const testimonies = [
    {
      description:
        "C'était une très belle expérience. Les cours à domicile ont réellement amélioré les notes de ma fille en mathématiques. On a eu un enseignant patient qui a su comprendre ses difficultés et relever son niveau.",
      customer: {
        name: "Bertine FONKEU",
        picture: default_profile,
        category: "Parent d'élève",
      },
    },
    {
      description:
        "Je suis très reconnaissant pour les cours à domicile que j'ai suivi. j'ai surtout apprécié la rigueur dans le travail, ce qui m'a permis de réussir mon BEPC avec mention \"Bien\" .",
      customer: {
        name: "Michel BAYAK",
        picture: default_profile,
        category: "Élève",
      },
    },
    {
      description:
        "Ce que je retiens de vous c'est rigueur, discipline et travail. J'apprécie particulièrement votre jeunesse et dynamisme. Chaque séance de cours reserve toujours quelque chose de ouf. Merci.",
      customer: {
        name: "Axelle KENNE",
        picture: default_profile,
        category: "Élève",
      },
    },
    {
      description:
        "Au début, j'étais dubitatif. Mais après quelques séances, j'ai très vite compris que j'avais à faire à des professionnels. Je recommande vivement à tous les parents à la recherche de soutien scolaire pour leurs enfants.",
      customer: {
        name: "Pierre OWONA",
        picture: default_profile,
        category: "Parent d'élève",
      },
    },
  ];
  return (
    <section
      id="testimonies"
      className="mt-10 sm:mt-20  flex flex-col  items-center pb-20 w-full"
    >
      <p className=" py-10 sm:py-20 text-[35px] sm:text-[50px] font-figtree text-center ">
        Ils nous ont fait <span className="text-[#0083FF] ">confiance</span>
      </p>
      <div className="hidden sm:flex w-full  flex-wrap gap-10 justify-center">
        {testimonies.map((item, index) => (
          <CustomerTestimonial key={index} Testimony={item} />
        ))}
      </div>
      <div className="w-full block sm:hidden">
        <Carousel
          data={testimonies.map((item, index) => (
            <CustomerTestimonial key={index} Testimony={item} />
          ))}
          lastIndex={3}
        />
      </div>
    </section>
  );
}

export default Testimonies;
