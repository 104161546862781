import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Hero from "./Hero";

import Subjects from "./Subjects";
import Classes from "./Classes";
import Contact from "./Contact";
import Advantages from "./Advantages";
import Testimonies from "./Testimonies";

import Process from "./Process";
import Faq from "./Faq";
import Footer from "./Footer";
import ScrollTopButton from "./ScrollTopButton";
function Layout() {
  return (
    <section>
      <div>{<Outlet />}</div>

      <Subjects />
      <Classes />
      <Contact />
      <Advantages />
      <Testimonies />
      <Process />
      <Faq />
      <Footer />
      <ScrollTopButton />
    </section>
  );
}

export default Layout;
