function CustomerTestimony({ Testimony }) {
  return (
    <div className="flex flex-col justify-between gap-4 w-[300px] md:w-[400px] bg-green-100 rounded-[32px] p-[28px] shadow-sm  ">
      <p className="font-[400] font-nexa-thin text-[#031432] text-[16px] italic ">
        {Testimony.description}
      </p>
      <div className="flex gap-2">
        <img
          src={Testimony.customer.picture}
          alt=""
          className="w-[48px] h-[48px] object-cover rounded-full "
        />
        <div className="flex flex-col font-nexa-normal">
          <p className="text-[17px] text-[#00BFA5]  ">
            {Testimony.customer.name}
          </p>
          <p className="text-[12px] text-[#6C87AE] ">
            {Testimony.customer.category}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CustomerTestimony;
