import "./App.css";
import { BrowserRouter as Router, Routes,Route } from "react-router-dom";

import Layout from "./components/Layout";
import Home from "./Pages/Home";
import CoursDomicile from "./Pages/CoursDomicile";
import CoursSpecial from "./Pages/CoursSpecial";
import CoachingScolaire from "./Pages/CoachigScolaire";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout/>} >
          <Route path="/" element={<Home/>}/>
          <Route path="/cours-a-domicile" element={<CoursDomicile/>} />
          <Route path="/cours-specialises" element={<CoursSpecial/>} />
          <Route path="/coaching-scolaire" element={<CoachingScolaire/>} />
        </Route>
        
    </Routes>
    </Router>
  )
}

export default App;
