import { Link } from "react-router-dom";
import logo_full_white from "../assets/logo-full-white.png";
import facebook_logo from "../assets/facebook_logo_blanc.png";
import linkedin_logo from "../assets/linkedin_logo_blanc.png";
function Footer() {
  const scrollToAnchor = (anchorId) => {
    const anchor = document.getElementById(anchorId);
    if (anchor) {
      const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <footer
      id="footer"
      className="flex flex-col   bg-[#015598] py-20 px-[5%] sm:p-20 "
    >
      <section className="flex flex-col sm:flex-row sm:justify-between gap-10    text-white">
        <div className="flex flex-col gap-2 font-nexa-normal">
          <img
            src={logo_full_white}
            alt="logo-flexing-academy"
            className="w-[200px] "
          />
          <p className="mt-5 cursor-default">Yaoundé, Cameroun</p>
          {/* <a
            href="mailto:flexingacademy@gmail.com"
            className="hover:text-yellow-400 cursor-pointer"
          >
            contact@flexingacademy.cm
          </a> */}
          <a
            href="tel:698499763"
            className="hover:text-yellow-400 cursor-pointer"
          >
            +237 698 499 763
          </a>
          <a
            href="tel:653848474"
            className="hover:text-yellow-400 cursor-pointer"
          >
            +237 653 848 474
          </a>
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between gap-10 flex-wrap bg-[#015598]  text-white">
          <div className="flex flex-col gap-2 font-nexa-normal text-[16px] ">
            <h3 className="text-yellow-400 font-nexa-bold  ">SERVICES</h3>
            <Link
              to="/cours-a-domicile"
              className="hover:text-yellow-400 cursor-pointer"
            >
              Cours à domicile
            </Link>
            <Link
              to="/cours-specialises"
              className="hover:text-yellow-400 cursor-pointer"
            >
              Cours spécialisés
            </Link>
            <Link
              to="/coaching-scolaire"
              className="hover:text-yellow-400 cursor-pointer"
            >
              Coaching scolaire
            </Link>
          </div>
          <div className="flex flex-col gap-2 font-nexa-normal text-[16px] ">
            <h3 className="text-yellow-400 font-nexa-bold  ">
              POUR LES PARENTS
            </h3>
            <p>Comment ça marche ?</p>
            <p
              className="hover:text-yellow-400 cursor-pointer"
              onClick={() => {
                scrollToAnchor("process");
              }}
            >
              Quelle est la procédure ?
            </p>
            <Link
              to="https://calendly.com/flexingacademy/appel"
              className="hover:text-yellow-400 cursor-pointer"
            >
              Appelez moi
            </Link>
          </div>
          <div className="flex flex-col gap-2 font-nexa-normal text-[16px] ">
            <h3 className="text-yellow-400 font-nexa-bold  ">
              POUR LES ELEVES
            </h3>
            <p
              onClick={() => {
                scrollToAnchor("classes");
              }}
              className="hover:text-yellow-400 cursor-pointer"
            >
              Quelles classes ?
            </p>
            <p
              className="hover:text-yellow-400 cursor-pointer"
              onClick={() => {
                scrollToAnchor("subjects");
              }}
            >
              Quelles matières ?
            </p>
            <p>Notre groupe WhatsApp</p>
          </div>
          <div className="flex flex-col gap-2 font-nexa-normal text-[16px] ">
            <h3 className="text-yellow-400 font-nexa-bold  ">
              POUR LES ENSEIGNANTS
            </h3>
            <Link
              to="https://wa.me/237698499763"
              className="hover:text-yellow-400 cursor-pointer"
            >
              Devenir enseignant
            </Link>
            <Link
              to="https://wa.me/237698499763"
              className="hover:text-yellow-400 cursor-pointer"
            >
              Devenir coach scolaire
            </Link>
            <Link
              to="https://wa.me/237698499763"
              className="hover:text-yellow-400 cursor-pointer"
            >
              Qui sont nos enseignants?
            </Link>
          </div>
          <div className="flex flex-col gap-2 font-nexa-normal text-[16px] ">
            <h3 className="text-yellow-400 font-nexa-bold  ">SUIVEZ-NOUS</h3>
            <div className="flex gap-5">
              <Link
                to="https://www.facebook.com/FlexingAcademy"
                className="group rounded-full overflow-hidden border p-3 cursor-pointer hover:bg-blue-100"
              >
                <img
                  className="group-hover:bg-blue-100  object-cover  size-5"
                  src={facebook_logo}
                  alt=""
                />
              </Link>
              <Link className="group rounded-full overflow-hidden border p-3 cursor-pointer hover:bg-blue-100">
                <img
                  className="group-hover:bg-blue-100   object-cover  size-5"
                  src={linkedin_logo}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="w-full bg-gray-200 h-[1px] mt-20 mb-1"></div>
      <p className="text-white text-right font-nexa-normal text-[14px] sm:text-[18px] ">
        {new Date().getFullYear()} © Flexing Academy - Tous droits réservés
      </p>
    </footer>
  );
}

export default Footer;
