import Navbar from "../components/Navbar";
import img_cad from "../assets/img-cours-a-domicile.jpg";
import { Link } from "react-router-dom";
import img_cad_2 from "../assets/img-1-enseignant-1-eleve.jpg";
import { Helmet } from "react-helmet";
function CoursDomicile() {
  document.documentElement.scrollTop = 0;
  return (
    <section>
      <Helmet>
        <title>Cours à domicile | Flexing Academy</title>
        <meta
          name="description"
          content="Approprié pour des cours sur une longue durée. Pour préparer les examens de passage ou examens officiels"
        />
      </Helmet>
      <Navbar />
      <div className="flex flex-col px-3 py-4 sm:px-10 sm:pb-20 pt-32 sm:pt-40 bg-[#0083FF]  sm:flex-row gap-5 sm:gap-12  shadow-md sm:shadow-sm text-white">
        <img
          src={img_cad}
          alt="ensseignant avec des élèves"
          className="w-full sm:w-1/2 h-[300px] sm:h-[400px] object-cover rounded-[10px] "
        />
        <div>
          <div className="flex justify-center sm:justify-start">
            <span className=" px-5 py-1 sm:py-2  font-nexa-bold border-2 border-white rounded-md  text-white ">
              Populaire
            </span>
          </div>
          <h1 className=" py-2  font-figtree  text-center sm:text-start text-[30px] sm:text-[50px]  ">
            Cours à domicile
          </h1>
          <p className="p-3 text-[20px]  font-nexa-normal text-justify ">
            Ce programme est approprié pour des cours de soutien sur une longue
            durée et sur plusieurs matières, permettant ainsi de sereinement
            préparer les évaluations séquentielles, les examens de passage et
            les examens officiels (CEP, FSLC, BEPC, PROBATOIRE, BAC, GCE O/A
            Level).
          </p>
          <Link
            to={"https://calendly.com/flexingacademy/appel"}
            className="w-full flex justify-center py-3 my-3 font-nexa-bold bg-white hover:bg-blue-50 text-center text-[#0083FF] text-[20px] rounded-md "
          >
            Commander
          </Link>
        </div>
      </div>

      {/* comment ça marche? */}
      <div className="px-[5%] ">
        <p className=" mb-10 mt-20 sm:m-0 sm:py-20 text-[30px] sm:text-[50px] font-figtree text-center ">
          <span className="">Comment ça marche ?</span>
        </p>
        <div className="flex flex-col sm:flex-row gap-5 w-full">
          {" "}
          <div className="sm:flex-1">
            <p className="text-gray-800 font-nexa-normal text-[18px] sm:text-[25px] ">
              À la Flexing Acad, nous accordons une place importante à l'écoute
              et aux échanges entre enseignants et élèves d'une part et
              ensignants et parents d'autre part. La{" "}
              <span className="text-[#0083FF] "> jeunessse</span>, le{" "}
              <span className="text-[#0083FF] ">dynamisme</span> et le niveau d'
              <span className="text-[#0083FF] ">expérience</span> de nos
              encadreurs facilitent ce processus permetant ainsi aux élèves
              d'être plus ouverts et aux enseignants de facilement dénicher les
              véritables difficultés aux quelles fait face l'élève . Ce qui
              fluidifie le processus d'apprentissage.
            </p>
          </div>
          <img
            src={img_cad_2}
            alt=""
            className="w-[full] sm:w-[40%] h-[300px] sm:h-[350px] object-cover rounded-md "
          />
        </div>

        {/* à votre disposition */}
        <div className="px-[5%] ">
          <p className=" mb-16 mt-20 sm:py-20 text-[30px] sm:text-[50px] font-figtree text-center ">
            <span className="">À votre disposition</span>
          </p>
          <div className="flex w-full flex-col sm:flex-row  gap-16 sm:justify-between">
            {accesoires.map((item, index) => (
              <div
                key={index}
                className="flex flex-col gap-2  items-center sm:flex-1"
              >
                <span className="material-symbols-outlined p-7 rounded-full text-[45px] text-[#0083FF] bg-blue-100 ">
                  {item.icon}
                </span>
                <p className="font-nexa-bold text-center text-[18px] sm:text-[20px] text-gray-800 ">
                  {item.title}
                </p>
                <p className="font-nexa-normal text-[16px] sm:text-[16px] text-gray-600 text-center sm:text-justify  ">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoursDomicile;

const accesoires = [
  {
    icon: "description",
    title: "Epreuves et Fiches de TDs",
    description:
      "Nous mettons à votre disposition des fiches de travaux dirigés minitieusement conçues par notre équipe d'enseignants. Aussi, des anciens sujets et épreuves de collèges de renom.",
  },
  {
    icon: "check",
    title: "Correction des épreuves et Fiches de TDs",
    description:
      "Nos enseignants s'assurent de corriger les fiches de travaux dirigés et épreuves avec nos élèves.",
  },
  {
    icon: "laptop_chromebook",
    title: "Séances pratiques",
    description:
      "Nos offrons des séances pratiques d'informatique pour les élèves ayant souscrit pour cette matière.",
  },
  {
    icon: "live_help",
    title: "Assistance en ligne",
    description:
      "Nos encadreurs aportent de l'aide à nos élèves en temps réel grâce à nos fora WhatsApp. ",
  },
];
