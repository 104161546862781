import classNames from "classnames";
import { useState } from "react";
function Faq() {
  const [openIndex, setOpenIndex] = useState(-1);
  const faqs = [
    {
      question: "Pourquoi faire des cours à domicle ? ",
      answer:
        "Il est important de faire des cours à domicile car ils permettent un enseignement individualisé, adapté aux besoins spécifiques de l'élève. L'enseignant à domicile consacre tout son temps et son attention à un seul élève pour comprendre et résoudre toutes ses préoccupations, chose qui n'est pas possible lorsqu'on a des dizaines d'élèves sous la main, ce qui accélère l'apprentissage. De plus, ils permettent d'économiser du temps et réduire le nombre de déplacements, limitant ainsi les risques d'incidents désagréables.",
    },
    {
      question: "Quels sont vos tarifs ?",
      answer:
        "Notre mission est de rendre l'éducation accessible. Nous avons donc pensé à toutes les couches sociales en adoptant des prix très abordables. Nos tarifs varient. Ils vont dépendre de plusieurs facteurs: le service voulu, la classe de l'élève, les matières souhaitées, le nombre de séances hebdomadaires/mensuelles et bien d'autres. Il faut discuter avec un conseiller client pour avoir un devis (c'est gratuit et sans engagements).",
    },
    {
      question: "Quels sont les préréquis pour commencer les cours ?",
      answer:
        "Pour commencer les cours à domicile vous n'avez pas  besoin de grand chose: un coin calme pour travailler est suffisant. Avoir un tableau serait idéal. Vous n'en avez pas? pas de souci. ",
    },
    {
      question:
        "Je n'ai pas de salle d'étude chez moi est-ce que c'est possible de faire des cours à domicile ?",
      answer:
        "Avoir un salle d'étude serait un atout mais un coin calme, une véranda ou un balcon fait bien l'affaire.",
    },
    {
      question:
        "Je trouve les notes de mon enfant assez-bien est-ce qu'il a encore besoin des cours ?",
      answer:
        "Dans le contexte actuel, le monde de l'emploi est devenu extrêmement compétitif et l'entrée dans les grandes écoles extrêmement sélective. Être moyen n'est plus suffisant. Les élèves doivent frôler l'excellence. C'est notre objectif à la Flexing Acad. Aussi, avoir des notes acceptables ne garantit pas une insertion professionelle réussie ou encore un après baccaluréat sans stress. C'est la raison pour laquelle nous ajoutons dans nos programmes un suivi orienté vie professionelle et du coaching scolaire.",
    },
  ];
  return (
    <section
      id="faq"
      className="mt-10 sm:mt-20  flex flex-col  items-center pb-20 w-full px-[5%] "
    >
      <p className=" py-10 sm:py-20 text-[30px] sm:text-[50px] font-figtree text-center ">
        <span className="text-[#0083FF] ">Questions </span> fréquemment posées
        <span className="text-[#0083FF] "> (</span>FAQ
        <span className="text-[#0083FF] ">)</span>
      </p>

      <div className="flex flex-col gap-6 w-full">
        {faqs.map((item, index) => (
          <div className="w-full border-b-2 border-[#0083FF] pb-1 ">
            <h3
              onClick={() => {
                openIndex === index ? setOpenIndex(-1) : setOpenIndex(index);
              }}
              className="flex justify-between items-center w-full font-nexa-bold text-[18px] sm:text-[21px] text-gray-800 cursor-pointer"
            >
              <span>{item.question} </span>
              <span className="material-icons">
                {openIndex === index ? "expand_less" : "expand_more"}
              </span>
            </h3>
            <p
              className={classNames(
                openIndex === index ? " max-h-[350px] " : "max-h-[0px] ",
                "font-nexa-normal transition-[max-height] duration-800 ease-in-out overflow-hidden text-[16px] sm:text-[18px] text-gray-600 ",
              )}
            >
              {item.answer}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Faq;
