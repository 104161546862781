import { Link } from "react-router-dom";
import whatsapp from "../assets/whatsapp.png";
function Contact() {
  return (
    <section
      id="contact"
      className="mt-20 px-[5%] flex flex-col  items-center pb-20 w-full"
    >
      <p className="py-20 text-[40px] sm:text-[50px] font-figtree text-center ">
        <span className="text-[#0083FF] ">Contactez</span>-nous
      </p>
      <div className="w-full flex flex-col  py-10 justify-center bg-[#0013FF] rounded-[20px] text-center font-nexa-bold text-white">
        <p className="text-[25px] sm:text-[30px] pb-5 ">Appelez-nous</p>
        <div className="flex flex-col sm:flex-row justify-center gap-7 sm:gap-10">
          <a
            href="tel:698499763"
            className="flex items-center border-2 pr-10 mx-auto sm:mx-0 bg-white text-[18px] sm:text-[22px] leading-[22px] text-[#0013FF] rounded-full "
          >
            <span className="material-icons bg-orange-500 p-2 sm:p-3 mr-3 rounded-full text-[25px] text-white">
              call
            </span>
            <span>698 499 763</span>
          </a>
          <a
            href="tel:653848474"
            className="flex items-center border-2 pr-10 mx-auto sm:mx-0 bg-white text-[18px] sm:text-[22px] leading-[22px] text-[#0013FF] rounded-full "
          >
            <span className="material-icons bg-yellow-400 p-2 sm:p-3  mr-3 rounded-full text-[25px] text-white">
              call
            </span>
            <span>653 848 474</span>
          </a>
        </div>
        <p className="text-[25px] sm:text-[30px] pt-12 pb-6">
          Message WhatsApp
        </p>
        <div className="flex justify-center">
          <a
            href="https://wa.me/237698499763"
            className="flex items-center border-2 pr-10 mx-auto sm:mx-0 bg-green-100 text-[18px] sm:text-[22px] leading-[22px] text-[#0013FF] rounded-full "
          >
            <img
              src={whatsapp}
              alt="icone whatsapp"
              className="size-12 mr-3 object-cover rounded-full "
            />
            <span>698 499 763</span>
          </a>
        </div>
        <p className="text-[25px] sm:text-[30px] pt-12 pb-6">
          Un conseiller client vous appelle
        </p>
        <div className="flex justify-center">
          <Link
            to={"https://calendly.com/flexingacademy/appel"}
            className="flex items-center border-2  pr-10 mx-auto sm:mx-0 bg-purple-100 text-[18px] sm:text-[22px] leading-[22px] text-[#0013FF] rounded-full "
          >
            <span className="material-icons bg-[#5A199B] sm:p-3 p-2.5  mr-3 rounded-full text-[30px] sm:text-[30px] text-white">
              support_agent
            </span>
            ETRE RAPPELÉ
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Contact;
