import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Services from "../components/Services";

function Home() {
  document.documentElement.scrollTop = 0;
  return (
    <section id="home">
      <div className="sm:max-h-screen  flex flex-col overflow-hidden">
        <Navbar />
        <Hero />
      </div>
      <Services />
    </section>
  );
}

export default Home;
