import classNames from "classnames";
import { useState } from "react";

function ScrollTopButton() {
  const [scrollTop, setScrollTop] = useState(false);

  const handleScrollTop = () => {
    if (window.scrollY > 2 * window.innerHeight) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };
  window.addEventListener("scroll", handleScrollTop);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {scrollTop && (
        <div
          onClick={scrollToTop}
          className={classNames(
            " fixed z-[200] bottom-5 right-5 animate-bounce hover:animate-none flex items-center justify-center rounded-full shadow-xl p-3 md:p-4 cursor-pointer bg-white hover:bg-gray-100",
          )}
        >
          <span className="material-icons text-[#0083FF]">north</span>
        </div>
      )}
    </div>
  );
}

export default ScrollTopButton;
