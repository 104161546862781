function Advantages() {
  const advantages = [
    {
      icon: "school",
      description: "Enseignants qualifiés et expérimentés",
    },
    {
      icon: "person",
      description: "Suivi personalisé",
    },
    {
      icon: "calendar_month",
      description: "Flexibilité des horaires",
    },
    {
      icon: "trending_up",
      description: "Suivi du progrès ",
    },
    {
      icon: "sell",
      description: "Coûts abordables ",
    },
    {
      icon: "history_edu",
      description: "Évaluations mensuelles",
    },
    {
      icon: "cast_for_education",
      description: "Assistance en ligne",
    },
    {
      icon: "change_circle",
      description: "Possibilité de changer d'enseignant",
    },
  ];
  return (
    <section className="mt-10 sm:mt-20 px-[5%] flex flex-col  items-center pb-20 w-full">
      <p className="py-10 sm:py-20 text-[35px] sm:text-[50px] font-figtree text-center ">
        Pourquoi nous <span className="text-[#0083FF] ">choisir</span> ?
      </p>
      <div className="flex flex-wrap justify-center gap-5 sm:gap-10">
        {advantages.map((item, index) => (
          <div
            key={index}
            className="group flex flex-col items-center size-[150px] sm:size-[200px] py-5 sm:py-10 px-6 rounded-md border-2 border-[#0083FF] hover:border-[#0013FF] bg-white hover:bg-[#0013FF] text-gray-600 hover:text-gray-100   "
          >
            <span className="material-icons text-[40px] sm:text-[50px] text-[#0013FF] group-hover:text-white mb-2 ">
              {item.icon}
            </span>
            <p className="font-nexa-normal text-[16px] sm:text-[17px] text-center ">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Advantages;
