import logo_text_blue from "../assets/logo-text-blue.png";
import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

function Navbar() {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const toogleMobileNav = () => {
    setOpenMobileNav((prev) => !prev);
  };
  const handleNavbarStyle = () => {
    if (window.scrollY > 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  const scrollToAnchor = (anchorId) => {
    const anchor = document.getElementById(anchorId);
    if (anchor) {
      const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };
  window.addEventListener("scroll", handleNavbarStyle);
  return (
    <nav
      className={classNames(
        scroll ? "border-b border-gray-200 shadow-md" : "",
        "fixed z-[999] w-full flex justify-between items-center px-5 sm:px-10 pt-7 pb-5 sm:py-5 bg-white  ",
      )}
    >
      <Link to={"/"} className="w-[90px]  sm:w-[150px]">
        {" "}
        <img
          src={logo_text_blue}
          alt="logo"
          className=" object-cover  w-full h-full "
        ></img>
      </Link>

      <ul className="hidden  w-full sm:flex  gap-10 px-32 font-nexa-normal text-[17px] text-gray-600">
        <Link
          to={"/"}
          onClick={() => {
            scrollToAnchor("home");
          }}
          className="flex items-center cursor-pointer hover:text-[#0EAD8E]"
        >
          Accueil
        </Link>{" "}
        <li className="group relative flex items-center cursor-pointer hover:text-[#0EAD8E]  ">
          Services{" "}
          <span className="material-icons rotate-0 group-hover:rotate-[180deg] transition ">
            expand_more
          </span>
          <div className="group-hover:block hidden absolute top-6 w-[250px]  py-4 px-2 border border-gray-200  -translate-x-1/4 rounded-md bg-gray-50  shadow-xl">
            <ul className="flex flex-col w-full items-center justify-around gap-2 font-nexa-bold text-black text-[17px]">
              <Link
                to={"/cours-a-domicile"}
                className=" w-full flex justify-center py-4 text-center rounded-md hover:bg-blue-100"
              >
                Cours à domicile
              </Link>
              <Link
                to={"/cours-specialises"}
                className="relative flex justify-center w-full py-4 text-center rounded-md hover:bg-blue-100"
              >
                Cours spécialisés
                <span className="absolute px-2 py-0.5 left-1/2 top-0 -translate-x-1/2 bg-[#0083FF] rounded-b-md text-white text-[9px] ">
                  Nouveau
                </span>
              </Link>{" "}
              <Link
                to={"/coaching-scolaire"}
                className="flex justify-center w-full py-4 text-center rounded-md hover:bg-blue-100"
              >
                Coaching scolaire
              </Link>
            </ul>
          </div>
        </li>
        <li
          onClick={() => {
            scrollToAnchor("faq");
          }}
          className="flex items-center cursor-pointer hover:text-[#0EAD8E]"
        >
          FAQs
        </li>
      </ul>
      <div className="flex top-0 items-center justify-center">
        <Link
          to={"https://calendly.com/flexingacademy/appel"}
          className="btn-rdv  relative font-nexa-bold text-[14px] sm:text-[20px] text-[#0013FF] px-5 sm:px-7 py-2 sm:py-3 border rounded-full border-[#0013FF] bg-white shadow-sm sm:shadow-md text-nowrap hover:bg-blue-50 "
        >
          Prendre un RDV
          <div className="absolute top-0 right-0  -translate-y-1/2   ">
            <span className="notification flex items-center justify-center font-nexa-bold text-white bg-red-500 size-5 sm:size-8 rounded-full shadow-sm">
              <span>1</span>
            </span>
          </div>
        </Link>
        <span
          onClick={() => setOpenMobileNav((prev) => !prev)}
          className="sm:hidden material-icons text-[#0083FF] text-[30px] cursor-pointer ml-3"
        >
          {openMobileNav ? "close" : "menu"}
        </span>
      </div>
      <ul
        className={classNames(
          openMobileNav ? "right-0" : "-right-[100vw]",
          "md:hidden fixed duration-500 ease-in-out top-0 z-[999] flex flex-col items-center justify-center gap-[50px] h-screen w-screen bg-[#0083FF] font-nexa-normal text-white ",
        )}
      >
        <Link
          to={"/"}
          onClick={() => {
            scrollToAnchor("home");
            toogleMobileNav();
          }}
          className="text-[22px]   hover:text-yellow-400 "
        >
          Accueil
        </Link>{" "}
        <li className="text-[22px]   hover:text-yellow-400 ">
          <h3
            className="flex items-center justify-center gap-2 pb-2 cursor-pointer"
            onClick={() => {
              setShowServices((prev) => !prev);
            }}
          >
            Services{" "}
            <span className="material-icons">
              {showServices ? "expand_less" : "expand_more"}
            </span>
          </h3>
          <div
            className={classNames(
              showServices ? "max-h-[300px]" : "max-h-0 !py-0",
              "transition-[max-height] duration-500 ease-linear box-border overflow-hidden flex flex-col gap-1 py-5 bg-[#0020FF] rounded-md text-gray-100 ",
            )}
          >
            <Link
              onClick={toogleMobileNav}
              to={"/cours-a-domicile"}
              className="hover:text-yellow-400 px-10 text-center py-4"
            >
              cours à domicile
            </Link>
            <Link
              onClick={toogleMobileNav}
              to={"/cours-specialises"}
              className="relative hover:text-yellow-400 px-10 text-center py-3"
            >
              cours spécialisés{" "}
              <span className="absolute px-1 py-0.5 right-2 top-0 text-[#0083FF] rounded-full bg-white font-nexa-bold text-[9px] ">
                Nouveau
              </span>
            </Link>
            <Link
              onClick={toogleMobileNav}
              to={"/coaching-scolaire"}
              className="hover:text-yellow-400 px-10 text-center py-4"
            >
              coaching scolaire
            </Link>
          </div>
        </li>
        <li className="text-[22px]   hover:text-yellow-400 ">
          <button
            onClick={() => {
              scrollToAnchor("contact");
              toogleMobileNav();
            }}
          >
            Contact
          </button>
        </li>
        {/* <li className="text-[22px]   hover:text-yellow-400  ">
          <button
            onClick={() => {
              scrollToAnchor("about");
              toogleMobileNav();
            }}
          >
            À Propos
          </button>
        </li> */}
        <li className="text-[22px]   hover:text-yellow-400 ">
          <button
            onClick={() => {
              scrollToAnchor("faq");
              toogleMobileNav();
            }}
          >
            FAQs
          </button>
        </li>
        <span
          onClick={toogleMobileNav}
          className="absolute top-6 right-5 material-icons flex justify-center items-center p-2 bg-white text-[#3A8EF6] rounded-full cursor-pointer"
        >
          close
        </span>
      </ul>
    </nav>
  );
}

export default Navbar;
